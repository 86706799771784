import React from "react";

const enTranslations = {
  "welcome": "Welcome",
    "workingHours": "Working Hours",
    "today": "Today",
    "clockIn": "Clock-in",
    "clockOut": "Clock Out",
    "location": "Location",
    "inbox": "Inbox",
    "seemore": "See More",
    "inboxItems": {
      "item1": "Smart Document",
      "item2": "Mom",
      "item3": "Meeting",
      "item4": "Tasks"
    },
  "mostusedservice": "Most used services",
  "mostusedItem": {
    "item1": "Smart Service",
    "item2": "Meeting Calendar",
    "item3": "Task",
  },
  "knowledgehub": "Knowledge Hub",
    "knowledgeItem": {
      "item1": "Employee Directory",
      "item2": "Shared Folder",
      "item3": "Links & Videos",
      "item4": "My Report",
      "item5": "My Assets",
      "item6": "Groups"
    },
  "allmytasks": "All My Task",
  "notask": "No Task",
  "mytaskcard": "My Task Card",
  "taskname": "Task Name",
  "assignedBy": "Assigned By",
  "acceptedby": "Accepted By",
  "duedate": "Due date",
  "meetingforToday": "Meeting For Today",
  "mymeeting": "My Meeting",
  "meetingName": "Meeting Name",
  "meetingdatetime": "Meeting Date & Time",
  "meetingloc": "Meeting Location",
  "meetingorg": "Meeting Organizer",
  "momcreator": "Mom Creater",

  "empOverview": "Employee Overview",

  "projects": "Projects",
  "myprojectcard": "My Project Card",
  "projectname": "Project Name",
  "description": "Description",
  "projmanager": "Project Manager",
  "teammembers": "Team Member",
  "startdate": "Start Date",
  "enddate": "End Date",
  "duration": "Duration",
  "noproject": "No Project!!",
  "assignedTask": "Assigned Task",
  "assignto": "Assigned To",
  "priority": "Priority",
  "completed": "Complete",
  "inprogress": "In Progress",
  "confirmurPart": "Confirm Your Participation",
  "yes": "Yes",
  "no": "No",
  "mayBe": "Maybe",
  "attendees": "Attendees",
  "notesTaker": "Notes Taker",
  "createmom": "Create MOM",
  "edit": "Edit",
  "cancelmeet": "Cancel Meeting",
  "nodocument": "No Document",

  "contentItems": {
    "item0": "Dashboard",
    "item1": "Smart Attendance",
    "item2": "Smart Service",
    "item3": "Meeting Calendar",
    "item4": "My Project",
    "item5": "My Tasks",
    "item6": "Smart Document",
    "item7": "Mom",
    "item8": "My Report",
    "item9": "Shared Folder",
    "item10": "Links & Videos",
    "item11": "Employee Directory",
    "item12": "Groups",
    "item13": "My Assets",
    "item14": "AI Note",
    "item15": "AI Document Engine",
    "item16": "BOT",
    "item17": "OCR & Scan"
  },


  "ceoInboxItem": {
    "item1": "Mom",
    "item2": "Smart Document",
    "item3": "Smart Service",
  },


    "hrproduct": "HR & Productivity",
    "advanceai": "Advanced AI",
  "newDoc": "New Document",

  "titless": {
    "inbox": "Inbox",
    "sentItems": "Sent Items",
    "archive": "Archive",
    "allDocuments": "All Documents",
    "viewMore": "View More"
  },

// task
  "titles": {
    "allTask": "All Task",
    "inProgressTask": "In Progress Task",
    "completedTask": "Completed Tasks",
    "pendingTask": "Pending Task",
    "overAllTask": "OverAllTask"
  },

  // Project
  "titlesss": {
    "allProject": "All Project",
    "inProgress": "In Progress",
    "completed": "Completed",
    "pending": "Pending",
    "hold": "Hold"
  },

  "shareFolder": "Shared Folder",
  "smartDocument": "Smart Document",
  "newfolder": "New Folder",
  "goBack": "Go Back",
  "minutesofMeeting": "Minutes of Meeting",
  "newMom": "New Mom",
  "mytask": "My Task",
  "newtasks": "New Task",
  "overalltask": "Over All Task",
  "createTask": "Create Task",
  "createproj": "Create Project",
  "viewMore": "View More",
  "meeting": "Meeting",
  "myTasks": "My Tasks",
  "smartServices": "Smart Services",
  "meetingCalendar": "Meeting Calendar",
  "task": "Task",
  "noMeetings": "No Meeting",
  "createmeet": "Create Meeting",
  "employeeId": "Emp Id",
  "dateofjoin": "DOJ",
  "qualification": "Qualification",
  "supervisor": "Supervisor",
  "annuallevbal": "Annual Leave Balance",
  "theme": "Themes",
  "changePass": "Change Password",
  "logOut": "Logout",
  "public": "Public",
  "private": "Private",
  "newproject": "New Project",
  "attendanceRep": "Attendance Report",
  "smartService": "Smart Service",
  "pending": "Pending",
  "forapproval": "For Approval",
  "approved": "Approved",
  "rejected": "Rejected",
  "request": "Request",
  "newrequest": "New Request",
  "requestLeave": "Request Leave",
  "requestSickleave": "Request Sick Leave",
  "requestworkfromRemote": "Request-Work From Remote",
  "others": "Others",
  "casual": "Casual",
  "sick": "Sick",
  "leaverequest": "Leave Request",
  "close": "Close",
  "date": "Date",
  "newservice": "New Service",
  "inprogressProj": "In Progress Projects",
  "document": "Document",
  "reviewed": "Reviewed",
  "view": "View",
  "docHistory": "Document History",
  "relatedAttach": "Related Attachements",
  "docComment": "Document Comments",
  "shareFolderView": "Shared Folder View",
  "upload": "Upload",

  };

export default enTranslations;
