import React, { useEffect, useState } from "react";
import CardOne from "../../components/CardOne";
import CardFour from "../../components/CardFour";
import CardThree from "../../components/CardThree";
import CardTwo from "../../components/CardTwo";
import SmartAttendance from "../../components/SmartAttendance";
import MeetingCalendar from "../../components/MeetingCalendar";
import RecentTask from "../../components/RecentTask";
import { Link } from "react-router-dom";
import HeaderHome from "../../components/Headerhome";
import QuickCard from "../../components/QuickCard";
import QuickAll from "../../components/QuickAll";
import CeoCard from "../../components/CeoCard";
import CeoInbox from "../../components/CeoInbox";
import CeoOverallTask from "../../components/CeoOverallTask";
import CeoOverallproject from "../../components/CeoOverallProject";
import { jwtDecode } from "jwt-decode";
import { useTheme } from "../../components/Theme/Themecontext";
import MeetingCard from "../../AtomicComponent/MeetingCard";
import { useTranslation } from "react-i18next";



function Dashboard() {

  const { t, i18n } = useTranslation();

  const baseUrl =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEV
    : process.env.REACT_APP_BASE_URL_PROD;

  const token = localStorage.getItem("token");
  const userInfo = jwtDecode(token);
  // console.log("dasboard", userInfo)

  const { styles,theme,isSystemLight } = useTheme();
  const { component, SvgStroke ,border,bluetext } = styles
  const [reloadCounter, setReloadCounter] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);

  const RELOAD_INTERVAL = 500;

  useEffect(() => {
    fetchUserclkInfo();
    const intervalId = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, RELOAD_INTERVAL);

    return () => clearInterval(intervalId);
  }, [isRefresh]);

  setTimeout(() => {
    setIsRefresh(false);
  }, 1000);

  
  const handleRefresh = () => {
    setIsRefresh(true);
  };

  const [meetingInfo, setMeetingInfo] = useState([]);

  const fetchUserclkInfo = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${baseUrl}/home/todayMeeting`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        setMeetingInfo(data.result);
      } else {
        throw new Error("Failed to fetch booking details");
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };
  return (
    <>
    
      <div className={`xl:mr-20 `}>
        <div className={`w-full flex gap-5 items-center ${i18n.language === 'ar' ? 'justify-center lg:justify-end' : 'justify-center lg:justify-start'}`}>
          <CardOne reloadCounter={reloadCounter} />
        </div>
        <div className="lg:hidden mt-5">
          <QuickCard />
        </div>

        {/* <div className="mt-4 ">
          <SmartAttendance reloadCounter={reloadCounter} />
        </div> */}

       

        <div className="mt-4 ">
          <div className={`my-3 flex justify-between  ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
            <p style={bluetext} className="text-[16px] font-[600]">{t('allmytasks')}</p>
            <Link to="/home/task">
              <p style={bluetext} className="text-[14px] font-[600] cursor-pointer ">{t ('seemore')}</p>
            </Link>
          </div>
          <RecentTask reloadCounter={reloadCounter} />
        </div>

        <div className="mt-4">
          <div className={`my-3 hidden md:flex justify-between ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
            <p style={bluetext} className="text-[16px] font-[600]">{t('meetingforToday')} </p>
            <Link to="/home/meeting">
              <p style={bluetext} className="text-[14px] font-[600]  cursor-pointer">{t('seemore')}</p>
            </Link>
          </div>
          <div className={`flex ${i18n.language === 'ar' ? 'lg:justify-end' : 'lg:justify-start'}`}>
            {meetingInfo.map((info, index) => (
              <MeetingCard meetingInfos={info} key={index} refresh={handleRefresh} />
            ))}
          </div>
          
        </div>

        
        {userInfo.role === 0   ? (
          <div className="mt-4 ">
            <div className={`my-3 hidden md:flex justify-between ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
              <p style={bluetext} className="text-[16px] font-[600]">{t('empOverview')}</p>
              <button onClick={handleRefresh}>
                <span style={bluetext} className="material-symbols-outlined">refresh</span>
              </button>
            </div>
            <CeoCard isRefresh={isRefresh} />
          </div>
        ) : null}

        {/* <div className="mt-4 ">
          <div className="my-3 hidden md:flex justify-between">
            <p className="text-[16px] font-[600]">Inbox</p>
            <button onClick={handleRefresh}>
              <span className="material-symbols-outlined">refresh</span>
            </button>
          </div>
          <CeoInbox isRefresh={isRefresh} />
        </div> */}

        {userInfo.role === 1  ? (
          <div className="mt-4 ">
            <div className={`my-3 hidden md:flex justify-between ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
              <p style={bluetext} className="text-[16px] font-[600]">{t('المشروعات')}</p>
              <Link to="/home/overallinprogresstask">
                <p style={bluetext} className="text-[14px] font-[600]">{t('seemore')}</p>
              </Link>
            </div>
            <CeoOverallTask reloadCounter={reloadCounter} />
          </div>
        ) : null}

        {userInfo.role === 1  || userInfo.role === 2  || userInfo.role === 0 ?  (
          <div className="mt-4 ">
            <div className={`my-3 hidden md:flex justify-between ${i18n.language === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
              <p style={bluetext} className="text-[16px] font-[600]">
                {t('projects')}
              </p>
              <Link to="/home/overallinprogressproject">
                <p style={bluetext} className="text-[14px] font-[600]">{t('seemore')}</p>
              </Link>
            </div>
            <CeoOverallproject reloadCounter={reloadCounter} />
          </div>
        ) : null}
      </div>

     
    </>
  );
}

export default Dashboard;
