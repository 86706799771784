import React from "react";

const arTranslations = {
  "welcome": "أهلا بك",
  "workingHours": "ساعات العمل",
  "today": "اليوم",
  "clockIn": "تسجيل الدخول",
  "clockOut": "تسجيل الخروج",
  "location": "موقع",
  "inbox": "صندوق الوارد",
  "seemore": "شاهد المزيد",
  "inboxItems": {
    "item1": "وثيقة ذكية",
    "item2": "دقائق الاجتماع",
    "item3": "اجتماع",
    "item4": "مهامي",
  },
  "mostusedservice": "لخدمات الأكثر استخداما",
  "mostusedItem": {
    "item1": "الخدمات الذكية",
    "item2": "تقويم الاجتماع",
    "item3": "المهام"
  },
  "knowledgehub": "مركز المعرفة",
  "knowledgeItem": {
    "item1": "دليل الموظف",
    "item2": "مجلد مشترك",
    "item3": "روابط وفيديوهات",
    "item4": "تقريري",
    "item5": "أصولي",
    "item6": "مجموعات"
  },
  "allmytasks": "كل مهامي",
  "notask": "لا توجد مهام",
  "mytaskcard": "بطاقة المهام الخاصة بي",
  "taskname": "اسم المهمة",
  "projectname": "اسم المشروع",
  "assignedBy": "تم التعيين من قبل",
  "acceptedby": "مقبولة من قبل",
  "duedate": "تاريخ الاستحقاق",
  "meetingforToday": "اجتماع لهذا اليوم",
  "mymeeting": "اجتماعي",
  "meetingName": "اسم الاجتماع",
  "meetingdatetime": "تاريخ ووقت الاجتماع",
  "meetingloc": "موقع الاجتماع",
  "meetingorg": "منظم الاجتماع",
  "momcreator": "موم الخالق",
  "assignedTask": "المهام المعينة",
  "assignto": "يسند إلى",
  "empOverview": "نظرة عامة على الموظفين",
  "priority": "أولوية",
  "completed": "المكتملة",
  "inprogress": "في تَقَدم ",
  "confirmurPart": "تأكيد مشاركتك",
  "yes": "نعم",
  "no": "لا",
  "mayBe": "ربما",
  "attendees": "الحاضرين",
  "notesTaker": "مدون الملاحظات",


  "contentItems": {
    "item0": "لوحة التحكم",
    "item1": "الحضور الذكي",
    "item2": "الخدمات الذكية",
    "item3": "تقويم الاجتماع",
    "item4": "المشروعات",
    "item5": "المهام",
    "item6": "وثيقة ذكية",
    "item7": "دقائق الاجتماع",
    "item8": "تقريري",
    "item9": "مجلد مشترك",
    "item10": "روابط وفيديوهات",
    "item11": "دليل الموظف",
    "item12": "مجموعات",
    "item13": "دليل الموظف",
    "item14": "ملاحظة منظمة العفو الدولية",
    "item15": "محرك المستندات بالذكاء الاصطناعي",
    "item16": "بوت",
    "item17": "لتعرف الضوئي على الحروف والمسح الضوئي",
  },

 
  "ceoInboxItem": {
    "item1": "دقائق الاجتماع",
    "item2": "وثيقة ذكية",
    "item3": "الخدمات الذكية",
  },

  "hrproduct": "الموارد البشرية والإنتاجية",
  "advanceai": "الذكاء الاصطناعي المتقدم",
  "newDoc": "مستند جديد",

  "titless": {
    "inbox": "صندوق الوارد",
    "sentItems": "العناصر المرسلة",
    "archive": "الأرشيف",
    "allDocuments": "جميع المستندات",
    "viewMore": "عرض المزيد"
  },
// task 
  "titles": {
    "allTask": "جميع المهام",
    "inProgressTask": "المهام قيد التنفيذ",
    "completedTask": "المهام المكتملة",
    "pendingTask": "المهام المعلقة",
    "overAllTask": "المهام الإجمالية"
  },

  "task": "مهامي",
  "alltask": "جميع المهام",
  "newtasks": "مهمة جديدة",
  "overalltask": "المهمة الشاملة",
  "createTask": "إنشاء مهمة",
  "viewMore": "عرض المزيد",

  // Project
  "titlesss": {
    "allProject": "جميع المشاريع",
    "inProgress": "قيد التنفيذ",
    "completed": "المكتملة",
    "pending": "معلق",
    "hold": "في الانتظار"
  },

  "projects": "المشروعات",
  "myprojectcard": "بطاقة مشروعي",
  "projectname": "اسم المشروع",
  "description": "وصف",
  "projmanager": "مدير المشروع",
  "teammembers": "أعضاء الفريق",
  "startdate": "تاريخ البدء",
  "enddate": "انتهاء العرض",
  "duration": "مدة",
  "noproject": "لا يوجد مشاريع!!",
  "createproj": "إنشاء مشروع",
  "employeeId": "الرقم الشخصي للموظف",
  "dateofjoin": "تاريخ الانضمام",
  "qualification": "مؤهل",
  "supervisor": "مشرف",
  "annuallevbal": "رصيد الإجازة السنوية",
  "theme": "السمة",
  "changePass": "تغيير كلمة المرور",
  "logOut": "تسجيل خروج",
  "public": " عام",
  "private": "خاص",
  "newproject": "مشروع جديد",
  "shareFolder": "مجلد مشترك",
  "newfolder": "ملف جديد",
  "noMeetings": "لا توجد اجتماعات",
  "meeting": "الاجتماعات",
  "smartDocument": "وثيقة ذكية",
  "goBack": "الرجوع للخلف",
  "minutesofMeeting": "دقائق الاجتماع",
  "newMom": "أمي جديدة",
  "createmeet": "إنشاء اجتماع",
  "attendanceRep": "تقرير الحضور",
  "smartService": "الخدمات الذكية",
  "pending": "قيد الانتظار",
  "forapproval": "للموافقة",
  "approved": "موافق عليه",
  "rejected": "مرفوض",
  "request": "طلب",
  "newrequest": "طلب جديد",
  "requestLeave": "طلب-إجازة",
  "requestSickleave": "طلب-إجازة مرضية",
  "requestworkfromRemote": "طلب العمل عن بعد",
  "others": "آخر..",
  "casual": "غير رسمي",
  "sick": "مريض",
  "leaverequest": "طلب الإجازة",
  "close": "يغلق",
  "newservice": "خدمة جديدة",
  "createmom": "خلق أمي",
  "edit":"يحرر",
  "date": "التاريخ",
  "inprogressProj": "مشروع قيد التقدم",
  "cancelmeet": "إلغاء الاجتماع",
  "nodocument": "لا توجد مستندات",
  "document": "وثيقة",
  "reviewed": "تمت مراجعته",
  "view": "منظر",
  "docHistory": "تاريخ الوثيقة",
  "relatedAttach": "المرفقات ذات الصلة",
  "docComment":"تعليقات الوثيقة",
  "shareFolderView": "عرض المجلد المشترك",
  "upload": "رفع",

};


export default arTranslations